import React, { useRef } from 'react';
import './index.css';

import italy1 from '../../assets/images/italy1.jpg';
import france1 from '../../assets/images/france1.jpg';
import greece1 from '../../assets/images/greece1.jpg';
import canada1 from '../../assets/images/canada1.jpg';

import Card from '../card';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const popularDestinations = [
    {
        id: 1,
        img: greece1,
        title: 'Maldives Tour',
        vertPrice: "$2.500",
        destinationText: '10 Days',
    },
    {
        id: 2,
        img: italy1,
        title: 'Italy Tour',
        vertPrice: "$1.300",
        destinationText: '6 Days',
    },
    {
        id: 3,
        img: france1,
        title: 'France Tour',
        vertPrice: "$2.500",
        destinationText: '10 Days',
    },
    {
        id: 4,
        img: canada1,
        title: 'Canada Tour',
        vertPrice: "$2.500",
        destinationText: '10 Days',
    },
    {
        id: 5,
        img: greece1,
        title: 'Maldives',
        vertPrice: "$2.500",
        destinationText: '10 Days',
    },
    {
        id: 6,
        img: greece1,
        title: 'Maldives',
        vertPrice: "$2.500",
        destinationText: '10 Days',
    },
]

export default function PopularDestinations() {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    return (
        <div className='popular_destinations section-padding container'>
            <div className="row">
                <div className="section-subtitle">Top Destination</div>
                <div className="section-title">Popular <span>Destination</span></div>
            </div>
            <div className='row'>
                {
                    <Swiper
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        modules={[Navigation]}
                        spaceBetween={30}
                        slidesPerView={1}
                        scrollbar={{ draggable: true }}
                        loop={true}
                        navigation={true}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            }
                        }}
                        style={{ width: '100%', height: '550px' }}
                    >
                        {
                            popularDestinations.map((destination, index) => {
                                return (
                                    <SwiperSlide key={index} className={`grid_item ${destination.className}`}>
                                        <Card
                                            img={destination.img}
                                            title={destination.title}
                                            vertPrice={destination.vertPrice}
                                            destinationText={destination.destinationText}
                                            isTour={false}
                                        />
                                    </SwiperSlide>
                                )
                            })
                        }
                        <div ref={prevRef}>Prev</div>
                        <div ref={nextRef}>Next</div>
                    </Swiper>
                }
            </div>
        </div>
    )
}