import React from 'react';
import './index.css';

const Card = (props) => {
    // TODO li item icons
    const {
        img,
        title,
        vertPrice,
        userCount,
        time,
        location,
        reviews,
        isTour = true,
        destinationText,
    } = props;

    const stars = [];

    if (reviews) {
        for (let i = 0; i < 5; i++) {
            stars.push(<i key={i} className={`star ${reviews.stars > i ? 'active' : ''}`}></i>);
        }
    }

    return (
        <div className="card">
            <div className="position-re o-hidden">
                <img className="card_image" src={img} alt="" />
            </div>
            <span className="card_vert_price">
                <a href="#0">{vertPrice}</a>
            </span>
            <div className="card_text_container">
                {
                    reviews &&
                    <div className="rating">
                        {stars}
                        <div className="reviews-count">({reviews.count} Reviews)</div>
                    </div>
                }
                <h5 className='card_text_title'>
                    <a href="tour-details.html">{title}</a>
                </h5>
                <div className="card_text_line"></div>
                {
                    isTour ?
                        <div className="row facilities">
                            <div className="tour_card_info">
                                <ul>
                                    <li><i className="ti-time"></i> {time}</li>
                                    <li><i className="ti-user"></i> {userCount}</li>
                                    <li><i className="ti-location-pin"></i> {location}</li>
                                </ul>
                            </div>
                        </div> :
                        <div className="row facilities">
                            <div className="dest_card_info">
                                <p>{destinationText}</p>
                                <div className="permalink">
                                    <a href="tour-details.html">
                                        Explore <i className="ti-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
};

export default Card;