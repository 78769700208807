import './index.css';
import { Link } from "react-router-dom";


const links = [
    { id: 1, to: '/', text: 'Home' },
    { id: 2, to: '/about', text: 'About' },
    { id: 3, to: '/tours', text: 'Tours' },
    { id: 4, to: '/destinations', text: 'Destinations' },
    { id: 5, to: '/blog', text: 'Blog' },
]

export default function Navigation({ className, onToggle }) {
    return (
        <div className={className} data-title={'nav'}>
            {links.map(link => (
                <Link key={link.id} to={link.to} onClick={onToggle}>{link.text}</Link>
            ))}
        </div>
    )
}