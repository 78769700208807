import './index.css';

import MiniAbout from "../../components/miniAbout";
import Slogan from "../../components/slogan";

import image from '../../assets/images/beach-houses.jpg';

export default function About() {

    return (
        <div className='about'>
            <Slogan
                image={image}
                title='The best travel agency'
                // text in curly braces will be highlighted (put in a span tag)
                subtitle={`We helping you find {your dream} vacation`}
            />
            <MiniAbout></MiniAbout>
        </div>
    )
}