import React, { useRef } from 'react';
import './index.css';

import Card from '../../card';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

function Country(props) {
    // TODO slider btn icons
    const {
        title,
        description,
        links,
        mainLink,
        swiperData,
        reverse = false,
    } = props;

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    return (
        <div className={`country ${reverse ? 'reverse' : ''}`}>
            <div className='country_text'>
                <h2 className='section-title2'>{title}</h2>
                <p>{description}</p>
                {
                    links &&
                    <div className="country_links">
                        {
                            links.map((link, index) => {
                                return <div key={index}>
                                    <a key={index} href={link.href}>{link.text}</a>
                                    <i className="ti-location-pin"></i>
                                </div>
                            })
                        }
                    </div>
                }
                {
                    mainLink &&
                    <div className="country_main_link">
                        <a href={mainLink.href} data-scroll-nav="1" className="">
                            <span>{mainLink.text} <i className="ti-arrow-right"></i></span>
                        </a>
                    </div>
                }
            </div>
            <div className="country_swiper">
                {
                    <Swiper
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        modules={[Navigation]}
                        spaceBetween={30}
                        slidesPerView={1}
                        scrollbar={{ draggable: true }}
                        loop={true}
                        navigation={true}
                        breakpoints={{
                            1024: {
                                slidesPerView: 2,
                            },
                        }}
                        style={{ width: '100%', height: '450px' }}
                    >
                        {
                            swiperData.map((tour, index) => {
                                return (
                                    <SwiperSlide key={index} className={`grid_item ${tour.className}`}>
                                        <Card
                                            img={tour.img}
                                            title={tour.title}
                                            vertPrice={tour.vertPrice}
                                            userCount={tour.userCount}
                                            time={tour.time}
                                            location={tour.location}
                                        />
                                    </SwiperSlide>
                                )
                            })
                        }
                        <div ref={prevRef}>Prev</div>
                        <div ref={nextRef}>Next</div>
                    </Swiper>
                }
            </div>
        </div>
    );
}

export default Country;