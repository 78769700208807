import './index.css';
import italy1 from '../../assets/images/italy1.jpg';
import france1 from '../../assets/images/france1.jpg';
import greece1 from '../../assets/images/greece1.jpg';
import canada1 from '../../assets/images/canada1.jpg';

import Country from './country';

const countries = [
    {
        id: 1,
        img: greece1,
        title: 'Maldives Tour',
        vertPrice: "$2.500",
        time: '10 Days',
        userCount: '12+',
        location: 'Maldives',
    },
    {
        id: 2,
        img: italy1,
        title: 'Italy Tour',
        vertPrice: "$1.300",
        time: '6 Days',
        userCount: '6+',
        location: 'Italy',
    },
    {
        id: 3,
        img: france1,
        title: 'France Tour',
        vertPrice: "$2.500",
        time: '10 Days',
        userCount: '12+',
        location: 'France',
    },
    {
        id: 4,
        img: canada1,
        title: 'Canada Tour',
        vertPrice: "$2.500",
        time: '10 Days',
        userCount: '12+',
        location: 'Canada',
    },
    {
        id: 5,
        img: greece1,
        title: 'Maldives',
        vertPrice: "$2.500",
        time: '10 Days',
        userCount: '12+',
        location: 'Maldives',
    },
]

const links = [
    {
        href: '#0',
        text: 'Roma',
    },
    {
        href: '#1',
        text: 'Roma',
    },
    {
        href: '#2',
        text: 'Roma',
    },
    {
        href: '#3',
        text: 'Roma',
    },
    {
        href: '#4',
        text: 'Roma',
    },
    {
        href: '#5',
        text: 'Roma',
    },
    {
        href: '#6',
        text: 'Roma',
    },
    {
        href: '#7',
        text: 'Roma',
    },
    {
        href: '#8',
        text: 'Roma',
    },
]

export default function Countries() {
    return (
        <div className='countries section-padding container'>
            <div className="row">
                <div className="section-subtitle">Most Popular</div>
                <div className="section-title">Travel <span>Countries</span></div>
            </div>
            <Country
                title='Italy, Europe'
                description='Italy is a country located in southern Europe and is known for its rich history, art, culture, and cuisine.'
                mainLink={{ href: '#0', text: 'All Tours' }}
                links={links}
                swiperData={countries}
            />
        </div>
    )
}