class GlobalHelper {
    formatSubtitle(text) {
        const parts = text.split(/(\{[^}]+\})/g); // Split text at each {}
        return parts.map((part, index) => {
            // Check if the part is a highlighted text
            if (part.startsWith('{') && part.endsWith('}')) {
                return <span key={index}>{part.slice(1, -1)}</span>;
            }
            return part; // Return normal text as is
        });
    }
}

export default new GlobalHelper();