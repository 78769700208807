import React, { useState } from 'react'
import './index.css'
import Navigation from './navigation'
import holaCaribeLogo from '../../assets/images/Hola Caribe.png'

export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleNavMenu = () => {
        setMenuOpen(prev => !prev)
    }

    return (
        <div className={`header ${menuOpen ? 'collapsed' : ''}`}>
            <img className="logo" src={holaCaribeLogo} />
            <button type='button' className="navbar_toggler" onClick={toggleNavMenu}>
                <i className='navbar_toggler_icon' />
            </button>
            <Navigation
                className={'nav_items'}
                onToggle={toggleNavMenu}
            />
        </div>
    )
}