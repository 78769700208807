import './index.css';
import global_helper from '../../helpers/global_helper';

export default function Slogan(props) {
    const {
        image,
        title,
        subtitle,
    } = props;

    return (
        <div class="slogan" style={{ backgroundImage: `url(${image})` }}>
            <div class="container">
                <div class="row">
                    <div class="slogan_col">
                        <h5>{title}</h5>
                        <h1>{global_helper.formatSubtitle(subtitle)}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}