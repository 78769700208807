import './index.css';

export default function Banner() {
    // TODO li item icons
    return (
        <div className='dynamic_video_wrapper position-re'>
            <video width="100%" height="100%" autoPlay="autoplay" muted preload="auto" loop="loop">
                <source src="https://duruthemes.com/demo/html/travol/travel-video.mp4" type="video/mp4" />
            </video>
            <div className="wrap_content">
                <div className="container">
                    <div className="row">
                        <h1><a href="tour-details.html">Costa Victoria Cochin</a></h1>
                        <h4>
                            <i className="ti-location-pin"></i> Maldives &nbsp;&nbsp; <i className="ti-timer"></i> 4 Days + 3 Nights
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}