import './index.css';
import italy1 from '../../assets/images/italy1.jpg';
import france1 from '../../assets/images/france1.jpg';
import greece1 from '../../assets/images/greece1.jpg';
import canada1 from '../../assets/images/canada1.jpg';
import Card from '../card';

const popularTours = [
    {
        id: 1,
        img: greece1,
        title: 'Maldives Tour',
        vertPrice: "$2.500",
        time: '10 Days',
        userCount: '12+',
        location: 'Maldives',
        reviews: {
            count: 2,
            stars: 4,
        },
        className: 'grid_item_1',
    },
    {
        id: 2,
        img: italy1,
        title: 'Italy Tour',
        vertPrice: "$1.300",
        time: '6 Days',
        userCount: '6+',
        location: 'Italy',
        className: 'grid_item_2',
    },
    {
        id: 3,
        img: france1,
        title: 'France Tour',
        vertPrice: "$2.500",
        time: '10 Days',
        userCount: '12+',
        location: 'France',
    },
    {
        id: 4,
        img: canada1,
        title: 'Canada Tour',
        vertPrice: "$2.500",
        time: '10 Days',
        userCount: '12+',
        location: 'Canada',
    },
    {
        id: 5,
        img: greece1,
        title: 'Maldives',
        vertPrice: "$2.500",
        time: '10 Days',
        userCount: '12+',
        location: 'Maldives',
    },
]

export default function PopularTours() {
    return (
        <div className='section-padding container'>
            <div className='popular_tours row'>
                <div className="col-md-12">
                    <div className="section-subtitle"><span>Choose your place</span></div>
                    <div className="section-title">Popular <span>Tours</span></div>
                </div>
            </div>
            <div className="row popular_tours_grid">
                {
                    popularTours.map((tour, index) => {
                        return (
                            <div key={index} className={`grid_item ${tour.className}`}>
                                <Card
                                    img={tour.img}
                                    title={tour.title}
                                    vertPrice={tour.vertPrice}
                                    userCount={tour.userCount}
                                    time={tour.time}
                                    location={tour.location}
                                    reviews={tour.reviews}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}