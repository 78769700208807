import Footer from '../../components/footer';
import Header from "../../components/header";
import Home from "../home";

export default function layoutContainer({children}){
    return(
        <>
            <Header id='header'></Header>
            <>{children}</>
            <Footer></Footer>
        </>
    )
}