import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LayoutContainer from './containers/layoutContainer';
import Home from "./containers/home";
import About from "./containers/about";

function App() {
    return (
        <BrowserRouter>
            <LayoutContainer>
                <Routes>
                    <Route path="" element={<Home />} />
                    <Route path="/about" element={<About />} />
                </Routes>
            </LayoutContainer>
        </BrowserRouter>
    );
}
export default App;
