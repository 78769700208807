import './index.css';

function HomeHeadingText(props) {
    const {
        title,
        subtitle,
        subtitleSpan,
    } = props;

    return (
        <div className="v_middle heading_caption">
            <div className="container">
                <div className="row">
                    <h4>{title}</h4>
                    <h1>{subtitle} <span>{subtitleSpan}</span></h1>
                </div>
            </div>
        </div>
    );
}

export default HomeHeadingText;