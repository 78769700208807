import './index.css';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import Banner from "../../components/banner";
import MiniAbout from "../../components/miniAbout";
import Countries from "../../components/countries";
import PopularTours from "../../components/popularTours";
// import CounterBlock from "../../components/counterBlock";
// import BlogPostBlock from "../../components/blogPostBlock";
import ExploreTheWorld from "../../components/exploreTheWorld";
import PopularDestinations from "../../components/popularDestinations";

export default function Home(){
    return (
        <div className='home'>
            <ExploreTheWorld></ExploreTheWorld>
            <MiniAbout></MiniAbout>
            <PopularTours></PopularTours>
            {/* <CounterBlock></CounterBlock> */}
            <PopularDestinations></PopularDestinations>
            <Banner></Banner>
            <Countries></Countries>
            {/* <BlogPostBlock></BlogPostBlock> */}
        </div>
    )

}