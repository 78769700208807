import './index.css';
import rome from '../../assets/images/rome.jpg';
import maldives1 from '../../assets/images/maldives1.jpg';
import maldives2 from '../../assets/images/maldives2.jpg';
import maldives3 from '../../assets/images/maldives3.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

import HomeHeadingText from './home-heading-text';

export default function ExploreTheWorld() {
    return (
        <div className='exploreTheWorld'>
            {
                <Swiper
                    modules={[Pagination, Autoplay]}
                    spaceBetween={3}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                    speed={1000}
                    style={{ width: '100%', height: '100vh' }}
                >
                    <SwiperSlide style={{ backgroundImage: `url("${rome}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="centered-text">
                            <HomeHeadingText
                                title='Explore the World'
                                subtitle='Discover the best city breaks'
                                subtitleSpan='Travol'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{ backgroundImage: `url("${maldives1}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="centered-text">
                            <HomeHeadingText
                                title='Explore the World'
                                subtitle='Discover the best city breaks'
                                subtitleSpan='Travol'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{ backgroundImage: `url("${maldives2}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="centered-text">
                            <HomeHeadingText
                                title='Explore the World'
                                subtitle='Discover the best city breaks'
                                subtitleSpan='Travol'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{ backgroundImage: `url("${maldives3}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="centered-text">
                            <HomeHeadingText
                                title='Explore the World'
                                subtitle='Discover the best city breaks'
                                subtitleSpan='Travol'
                            />
                        </div>
                    </SwiperSlide>
                </Swiper>
            }
        </div>
    )
}