import './index.css';
import italy1 from '../../assets/images/italy1.jpg';

export default function MiniAbout() {
    // TODO text styles
    return (
        <div className="section-padding container">
            <div className='mini_about_wrapper'>
                <div>
                    <div className="section-subtitle">The best travel agency</div>
                    <div className="section-title">Discover the <span>world</span> with our guide</div>
                    <p>You can choose any country with good tourism. Agency elementum sesue the aucan vestibulum aliquam justo in sapien rutrum volutpat. Donec in quis the pellentesque velit. Donec id velit ac arcu posuere blane.</p>
                    <p>Hotel ut nisl quam nestibulum ac quam nec odio elementum ceisue the miss varius natoque penatibus et magnis dis parturient monte.</p>
                    <ul className="list-unstyled about-list mb-30">
                        <li>
                            <div className="about-list-icon"> <span className="ti-check"></span> </div>
                            <div className="about-list-text">
                                <p>20 Years of Experience</p>
                            </div>
                        </li>
                        <li>
                            <div className="about-list-icon"> <span className="ti-check"></span> </div>
                            <div className="about-list-text">
                                <p>150+ Tour Destinations</p>
                            </div>
                        </li>
                    </ul>
                    <div className="phone-call">
                        <div className="icon"><span className="flaticon-phone-call"></span></div>
                        <div className="text">
                            <p>For information</p> <a href="tel:855-333-4444">855 333 4444</a>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="img-exp">
                        <div className="about-img">
                            <div className="img"> <img src={italy1} className="img-fluid" alt="" /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}